import { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './OSListFeature.css'
import deployDesktopimage from '../../images/deployserver_desktop.png'
import deployMobileImage from '../../images/deployserver_phone.png'
import deployTabletimage from '../../images/deployserver_tablet.png'
import { Tab, Tabs } from 'react-bootstrap'
import serverHeaderImage from '../../images/design/server_os.svg';
import apiService from '../../services/apiService'
import { Helmet } from 'react-helmet-async'

export const OSListFeature = () => {

    const [OSList, setOSList] = useState();

    const getOSList = async () => {
        let oslist = await apiService.getOperationSystems();

        setOSList(oslist.data.os)
    }

    const updateOS = async (osId) => {
        let elements = document.getElementsByClassName('osSelectionItem');

        for (var i = 0; i < elements.length; i++) {



            if (elements[i].dataset.key == osId) {
                elements[i].classList.add('osOptionSelected')
            } else {
                elements[i].classList.remove('osOptionSelected')

            }
        }
    }

    useEffect(() => {
        getOSList()
    }, [])
    return (
        <div>
            <Helmet>
                <title>Centnodes | Operating Systems</title>
                <meta
                    name="description"
                    content="Spin up a new VPS with your preferred operating system in just a seconds."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4 bg-transparant">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Operating Systems</span>
                            <span className="h2 mt-2">From Windows to Ubunutu to Fedora.</span>
                            <p className="mt-2">Spin up a new VPS with your preferred operating system in just a seconds.</p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom py-4 bg-white">
                <div className='container'>
                    <h3 className='text-center h3  mt-5'>Flexible Operating System Choices</h3>
                    <h6 className='text-center'>Spin up a new servers with your preferred operating system in just seconds.</h6>
                    <div className='row g-2 mt-5 make-equal '>
                        {OSList && OSList.length > 0 ?
                            OSList.map((osItem) => (
                                <div data-key={osItem.id} key={osItem.id} onClick={() => updateOS(osItem.id)} className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                                    <div className="d-flex flex-column bg-light rounded p-3 text-center justify-content-center align-items-center border bg-white h-100 w-100">

                                        <div className='row text-center'>
                                            <div className="col-md-6">
                                                <p>{osItem.name}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <img src={require('../../images/os/icon-' + osItem.family + '.png')} className="img-fluid mx-auto d-flex" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))

                            :
                            (
                                <p>No operating systems.</p>
                            )

                        }
                    </div>
                    <div className="row my-5 m-0 g-2 justify-content-center d-flex">
                        <h3 className='text-center  mt-5 h3'>Scale globally with a few clicks!</h3>
                        <h6 className='text-center'>Centnodes easy-to-use interface allows you to deploy high performance servers worldwide from any kind of device!</h6>
                        <div className="col-md-8 mt-3">
                            <Tabs
                                defaultActiveKey="Desktop"
                                id="deployServerTabs"
                                className="pb2"
                                justify
                            >
                                <Tab eventKey="Desktop" title="Desktop">
                                    <img src={deployDesktopimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Tablet" title="Tablet">
                                    <img src={deployTabletimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Mobile" title="Mobile">
                                    <img src={deployMobileImage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

