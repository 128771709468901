import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SiteNavigation } from '../../components/Navigation/SiteNavigation';
import './KnowledgeBase.css';
import Footer from '../../components/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import apiService from '../../services/apiService';
import contactImage from '../../images/design/knowledgebase_header.svg';
import { Form } from 'react-bootstrap';

const KnowledgeBase = () => {
    const [knowledgeBaseItems, setKnowledgeBaseItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null); // State to hold error messages
    const navigate = useNavigate();

    const categories = ['General', 'VPS', 'Web', 'Gaming', 'Panel', 'Other'];

    // Extract categoryName from the URL
    const pathParts = window.location.pathname.split('/').filter(Boolean);
    let categoryName = '';
    if (pathParts[0] === 'knowledgebase' && pathParts.length > 1) {
        categoryName = pathParts[1];
    }

    const getKnowledge = async () => {
        try {
            let items = await apiService.getKnowledgebaseItems();
            setKnowledgeBaseItems(items);
        } catch (err) {
            console.error('Failed to fetch knowledge base items:', err);
            setError('Failed to load knowledge base items. Please try again later.');
        }
    };

    const goToItem = (item) => {
        navigate('/knowledgebase/read/' + item.id);
    };

    useEffect(() => {
        getKnowledge();
    }, []);

    useEffect(() => {
        filterItems();
    }, [knowledgeBaseItems, categoryName, searchQuery]);

    const filterItems = () => {
        let items = knowledgeBaseItems;

        // Filter by category if categoryName is present
        if (categoryName) {
            items = items.filter(
                (item) => item.category.toLowerCase() === categoryName.toLowerCase()
            );
        }

        if (searchQuery) {
            items = items.filter(
                (item) =>
                    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (item.summary && item.summary.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        setFilteredItems(items);
    };

    return (
        <div>
            <SiteNavigation />
            <div className="border-bottom py-4 bg-test">
                <div className="container">
                    <div className="row align-items-center mt-5">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Knowledgebase</span>
                            <span className="h2 mt-2">Need help? Check out these awesome posts!</span>
                            <p className="mt-2">
                                Our community of skilled enthusiasts is ready to assist you.
                                Whether it's updating your operating system or installing software, feel free to look around.
                            </p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img">
                                <img src={contactImage} alt="server" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <span>
                    <Link to="/knowledgebase" className="d-inline">
                        Knowledgebase
                    </Link>
                </span>
                <div className="row mt-4 mb-2">
                    <div className="col-12 col-sm-4 col-md-3">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2 color-centnodes">Categories</h6>
                            <ul className="list-unstyled categoryList">
                                {categories.map((category) => (
                                    <li key={category} className="list-item">
                                        <Link to={`/knowledgebase/${category}`} className="category-link">
                                            <FontAwesomeIcon color={'#2f57a4'} icon={faArrowRight} />{' '}
                                            <span>{category}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="categoryBoxKnowledgeBase mt-1 p-3 card">
                            <h6 className="mt-2 color-centnodes">Want to contribute?</h6>
                            <p>
                                Want to help our community and post a tutorial or manual? Feel free to contact us.
                            </p>
                            <a href="/help" className="btn-centnodes p-2 mt-2 w-50" target="_blank" rel="noreferrer">
                                Contact
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-9">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2 color-centnodes">
                                {categoryName ? `${categoryName} Items` : 'All Items'}
                            </h6>
                            {/* Search Bar */}
                            <div className="col-md-12 mt-2">
                                <div className="searchFAQ">
                                    <Form onSubmit={(e) => e.preventDefault()}>
                                        <Form.Group>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-9 col-md-10 col-lg-10">
                                                    <Form.Control
                                                        type="text"
                                                        id="faqSearch"
                                                        placeholder="Search for example: How to recover a backup for my VPS."
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-2 col-lg-2 p-0">
                                                    <button className="btn btn-centnodes btn-lg">Search</button>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                            {/* Displaying Filtered Items */}
                            <ul className="list-unstyled mt-3">
                                {error ? (
                                    <p>{error}</p>
                                ) : filteredItems.length > 0 ? (
                                    filteredItems.map((item) => (
                                        <li key={item.id} onClick={() => goToItem(item)} className="list-item mt-2 p-3">
                                            <h5>{item.title}</h5>
                                            <button className='btn-centnodes'>Read more..</button>
                                        </li>
                                    ))
                                ) : (
                                    <p>No items found.</p>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon
                            className="backToTopIcon"
                            onClick={() => window.scrollTo(0, 0)}
                            icon={faArrowUp}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KnowledgeBase;
